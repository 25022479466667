import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"

const SiteImage = ( {imageName, imageStyle, wrapperStyle} ) => {

    const data = useStaticQuery(graphql`
      fragment servicesImage on File {
        childImageSharp {
          sizes(maxWidth: 800, quality:90) {
            ...GatsbyImageSharpSizes_tracedSVG
          }
        }
      }
  
      query {

        blackdress: file(relativePath: { eq: "img_files/blackdress.jpeg" }) {
          ...servicesImage
        } 
         
        blackjacket: file(relativePath: { eq: "img_files/blackjacket.jpeg" }) {
          ...servicesImage
        }
        
        blackjacket2: file(relativePath: { eq: "img_files/blackjacket2.jpeg" }) {
          ...servicesImage
        }

        blackjacket3: file(relativePath: { eq: "img_files/blackjacket3.jpeg" }) {
          ...servicesImage
        }
        
        blue_dress1: file(relativePath: { eq: "img_files/blue_dress1.jpeg" }) {
          ...servicesImage
        }

        brahms_double: file(relativePath: { eq: "img_files/brahms_double.jpeg" }) {
          ...servicesImage
        }

        brahms_poster: file(relativePath: { eq: "img_files/brahms_poster.jpeg" }) {
          ...servicesImage
        }   
        
        bw1: file(relativePath: { eq: "img_files/bw1.jpeg" }) {
          ...servicesImage
        }

        headshot: file(relativePath: { eq: "img_files/headshot.jpeg" }) {
          ...servicesImage
        }

        kathy_jake_reddress1: file(relativePath: { eq: "img_files/kathy_jake_reddress1.jpeg" }) {
          ...servicesImage
        }

        kathy_jake_studio: file(relativePath: { eq: "img_files/kathy_jake_studio.jpeg" }) {
          ...servicesImage
        }

        orch1: file(relativePath: { eq: "img_files/orch1.jpeg" }) {
          ...servicesImage
        }

        orch2: file(relativePath: { eq: "img_files/orch2.jpeg" }) {
          ...servicesImage
        } 
        orch3: file(relativePath: { eq: "img_files/orch3.jpeg" }) {
          ...servicesImage
        }

        orch4: file(relativePath: { eq: "img_files/orch4.jpeg" }) {
          ...servicesImage
        }

        orch5: file(relativePath: { eq: "img_files/orch5.jpeg" }) {
          ...servicesImage
        }

        portrait_ranch1: file(relativePath: { eq: "img_files/portrait_ranch1.jpeg" }) {
          ...servicesImage
        }

        portrait1: file(relativePath: { eq: "img_files/portrait1.jpeg" }) {
          ...servicesImage
        }

        portrait2: file(relativePath: { eq: "img_files/portrait2.jpeg" }) {
          ...servicesImage
        }

        portrait3: file(relativePath: { eq: "img_files/portrait3.jpeg" }) {
          ...servicesImage
        }

        reddress_depot: file(relativePath: { eq: "img_files/reddress_depot.jpeg" }) {
          ...servicesImage
        }

        reddress: file(relativePath: { eq: "img_files/reddress.png" }) {
          ...servicesImage
        }
        
        xmas_lights: file(relativePath: { eq: "img_files/xmas_lights.jpeg" }) {
          ...servicesImage
        }
        
      }
    `)
      
    return (
      <Img style={wrapperStyle} imgStyle={imageStyle} fluid={data[imageName].childImageSharp.sizes} />
    )}

    SiteImage.propTypes = {
        imageName: PropTypes.node.isRequired,
        imageStyle: PropTypes.object.isRequired,
        wrapperStyle: PropTypes.object
      }


    export default SiteImage