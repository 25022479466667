import PropTypes from "prop-types"
import React from "react"
import styled from '@emotion/styled'


    const SiteFooter = styled('header')`
      id: 'XSiteFooter';
      position:relative;
      width: 100%;
      height:  calc(2.0rem + 1.5vw);
      border-top: solid 1px #AAAAAA;
      background-color:#DDDDDD;
    `;

    const XCopyright = styled('label')`
    display: inline-block;
    width: 50%;
    font-size: calc(.6rem + .5vw);
    font-style: italic;
    padding-left: 5%;
    margin-top:calc(.50rem + .5vw);
    font-family: Palatino;
    cursor:default;
    `;

    const LastUpdated = styled('label')`
    display: inline-block;
    width: 50%;
    font-size: calc(.6rem + .5vw);
    font-style: italic;
    padding-right: 6%;
    text-align: right;
    margin-top:calc(.50rem + .5vw);
    font-family: Palatino;
    cursor:default;
    `;

    const getDate = () => {
      let now = new Date()
      const options = {  year: 'numeric', month: 'short', day: 'numeric' };
      return( now.toLocaleString(undefined, options))
    }

    const getYear = () => {
      let now = new Date()
      const options = {  year: 'numeric' };
      return( now.toLocaleString(undefined, options))
    }

    
    const Footer = (props) => (
  <>
    <SiteFooter id="XSiteFooter">
        <XCopyright>
        &#169;{getYear()} Katherine Audas
        </XCopyright>
        <LastUpdated>
              {getDate()}
        </LastUpdated>
        
      </SiteFooter>
             
</>
)


Footer.propTypes = {
  siteTitle: PropTypes.string
}

export default Footer
