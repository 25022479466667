



const GalleryImgData = (imageQueryResult) => {

    const galleryList = {
        'reddress_depot':{
            file: 'reddress_depot.jpeg',
            title: 'Katherine and Cello',
            desc: 'Portrait of Katherine',
            overlay_key: 'reddress_depot'
        },
        'orch1_v':{
            file: 'orch1_v.jpeg',
            title: 'Katherine and Orchestra',
            desc: 'Katherine playing cello',
            overlay_key: 'orch1'
        }, 
        'orch4_v':{
            file: 'orch4_v.jpeg',
            title: 'Katherine and orchestra',
            desc: 'Katherine at Ima Hogg Competition',
            overlay_key: 'orch4'
        },
        'blackdress':{
            file: 'blackdress.jpeg',
            title: 'Katherine',
            desc: 'Portrait of Katherine',
            overlay_key: 'blackdress'
        },
        'kathy_jake_reddress1':{
            file: 'kathy_jake_reddress1.jpeg',
            title: 'Katherine and Jacqueline Audas',
            desc: 'Portrait of Katherine and Jacqueline',
            overlay_key: 'kathy_jake_reddress1'
        }, 
        'bw1_v':{
            file: 'bw1_v.jpeg',
            title: 'Katherine',
            desc: 'Portrait of Katherine',
            overlay_key: 'bw1'
        },    
        'orch2':{
            file: 'orch2.jpeg',
            title: 'Katherine playing cello',
            desc: 'Playing the Elgar Cello Concerto',
            overlay_key: 'orch2'
        },    
        'brahms_double':{
            file: 'brahms_double.jpeg',
            title: 'Katherine and Jacqueline Audas',
            desc: 'Performing the Brahms Double Concerto',
            overlay_key: 'brahms_double'
        },       
        'orch3':{
            file: 'orch3.jpeg',
            title: 'Katherine and orchestra',
            desc: 'Playing Sinfonia Concertante',
            overlay_key: 'orch3'
        },   
        'blue_dress1':{
            file: 'blue_dress1.jpeg',
            title: 'Katherine during a recital',
            desc: 'Katherine playing cello',
            overlay_key: 'blue_dress1'
        },   
        'portrait1_v':{
            file: 'portrait1_v.jpeg',
            title: 'Katherine and cello',
            desc: 'Portrait of Katherine',
            overlay_key: 'portrait1'
        },   
        'orch5':{
            file: 'orch5.jpeg',
            title: 'Katherine and orchestra',
            desc: 'Katherine performing the Elgar Cello Concerto',
            overlay_key: 'orch5'
        },   
        'xmas_lights':{
            file: 'xmas_lights.jpeg',
            title: 'Katherine',
            desc: 'Katherine in lights',
            overlay_key: 'xmas_lights'
        },   
        'portrait3':{
            file: 'portrait3.jpeg',
            title: 'Katherine',
            desc: 'Portrait of Katherine',
            overlay_key: 'portrait3'
        }
    }
    

    // clone the gallery list to set length of completed gallery
    let images = Object.keys(galleryList)
    
    imageQueryResult.forEach(image => {
        let name = image.node.childImageSharp.sizes.originalName
        name = name.substr(0, name.lastIndexOf('.'))
        
        var index = images.indexOf(name)
        //console.log( name + ':' + index)
        if( index > -1 ){
            var desc = galleryList[name].desc
            image.imgStyle = { objectFit: 'cover', objectPosition: '50% 50%', alt:{desc}}
            image.imgStyle.title = name
            image.key = galleryList[name].overlay_key
            image.name = image.node.childImageSharp.sizes.originalName
            images[index] = image;  
        }               
    });
   
    return images
   
}

export default GalleryImgData