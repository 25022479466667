import React, {Component} from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import SiteVideo from './site_video'
import Overlay from '../components/overlay'
import useVideoData from './use-videodata'


const GalleryContainer = styled('div')` 
    position:relative;

`;

const GalleryVideo = styled('div')`
    position: relative;
    display: block;
    white-space: nowrap;
    overflow:hidden;
    height:100%;
    width: 100%;
    cursor: pointer;
`;

const VideoTitle = styled('p')`
    position:relative;
    font-size: calc(.5rem  + 1vw);
    font-family: Palatino;
    color: black;
    cursor:pointer;
    text-align: center;
    word-wrap: break-word;
    padding: .5rem 1rem .5rem 1rem;
`;

class VideoGallery extends Component {
    constructor(props) {
        super(props)

        this.state = {}
        this.state.overLayImage = "reddress_depot"
        this.state.showOverlay = false
        this.state.imageStyle = { objectFit: 'cover', objectPosition: '50% 50%', alt:'picture of Katherine'}
        this.state.style = {width:'calc(6rem + 5vw)', height:'calc(10rem + 10vw'}
        this.state.videos = useVideoData();
        console.log(this.state.videos)
        this.state.keys = Object.keys(this.state.videos)
    }



    render=()=>{
        
        return(
            <>
            { this.state.showOverlay ? <Overlay id="vidoe_gallery_overlay"><SiteVideo title={"video.title"} videoData={""} /></Overlay>  : null } 
            <GalleryContainer>
            
            {this.state.keys.map( (key, i) => (
                <GalleryVideo  
                    id = {'galleryvideo_' + i}
                    key={i}
                    tabIndex={0}
                    //onClick={this.HandlePicClick}
                    >
                    <SiteVideo title={this.state.videos[key].title} videoData={this.state.videos[key]} />
                    <VideoTitle>{this.state.videos[key].title}</VideoTitle>
                </GalleryVideo>
            ))} 
            </GalleryContainer>
        </>
        )
    }
}

export default VideoGallery

VideoGallery.propTypes = {
    videoData: PropTypes.array
  }