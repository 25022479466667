import { graphql, useStaticQuery } from 'gatsby';

const useSiteMetadata = () => {
    const data = useStaticQuery(graphql`
    query{
        site{
          siteMetadata{
            title
            description
            author
            headerTitle
            headerMenus { 
              label
              order
            }
            bioMenus {
              label
              order
            }
          }
        }
      }
    `);

return data.site.siteMetadata;
}


export default useSiteMetadata;