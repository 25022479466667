import React from 'react'
import Panel from './panel'
import styled from '@emotion/styled'
import SiteImage from '../../images/site_images'
import PanelHeader from './panelHeader'
import AccordionUnit from '../ui/accordion'
import CelloPrint from '../../../static/cello_print.png'
import useSiteMetadata from '../../hooks/use-sitemetadata';
import {breakpoint} from '../../styles/breakpoints'



const BioPanel = () => {

    const  {bioMenus} =  useSiteMetadata(); 

    const PanelContent = styled('article')`
        position: relative;
        width:100%; 
        background-color:white;  
        border-bottom: 10px solid  gray; 
        border-top: 1px solid gray;
        height: 60vw;   
        @media only screen and ${breakpoint.mobileL} {
            height: 40vw;
        }
    `;
    const BioContainer = styled('div')`
        display: grid;
        position: relative;
        grid-template-columns: 33% auto;
        grid-template-areas: 'a b';
        margin-top: 0px;
        width: 100%;
        height: 100%;
        overflow:hidden;

        @media only screen and ${breakpoint.mobileL} {
            grid-template-columns: 20% auto 30%;
        grid-template-areas: 'a b c';
        }
    `;
    const BioText = styled('div')`
        font-size: calc(.7rem + .7vw);
        overflow-y: auto;
        padding: 10px 10px 10px 20px;
        height: 100%;
        width: 100%;
        cursor:default;
        background-color: #EEEEEE;

        ul {
            margin-left: .3rem;

            li {
                margin-left: 0;
            }
        }
        
        @media only screen and ${breakpoint.mobileL} {
            padding: 30px 30px 10px 30px;
        }
    `;
    const BioPic = styled('div')`
        
    display: none;
    @media only screen and ${breakpoint.mobileL} {
        display:block;
       grid-area: c;
       box-sizing: border-box;
        width: 100%;
        padding-left: calc(5px + 1vw);
        padding-top: 6%;
    }
    `;

    const BioCello = styled('div')`
        grid-area: a;
        width: 100%;
        height: 100%;
        background-image: url(${CelloPrint});
        background-repeat: no-repeat;
        background-size: contain;
    `;

    const BioTextContainer = styled('div')`
        grid-area: b;
        position: relative;
        box-sizing: border-box;
        padding-left: calc(5px + 1vw);
        top: 5%;
        width: 100%;
        max-height: 90%;
        overflow-y:auto;
    `;

    const MenuContainer = styled('div')`
        display:flex;
        flex-direction: column;
        justify-content: flex-start;
        position: relative;
       
        top: 5%;
        height:80%;
        float: right;
        width: 90%;
       
    `;

    const MenuItem = styled('button')`
    font-size:  calc(.7rem + .7vw);
    font-family: serif;
    font-weight: 600;
    padding-top: 2px;
    padding-bottom: 8px;
    background: linear-gradient(#aaaaaa, #dddddd);
    width: 100%;
    text-align: left;
    
    
    cursor: auto;
    border: 0;
    opacity: 0.90;
    margin-bottom: 2%;
    transition: transform .5s;
    &:hover {
        transform: scale(1.05);
        color: red;
    }
    &.bioSelected{
        color: white;
        background: linear-gradient(#555555, #999999);
        opacity: .95;
    }

    @media only screen and ${breakpoint.mobileL} {
        padding-top: calc( 2px + 1vw);
        padding-bottom: calc( 2px + 1vw);
    }
    `;

    const HandleMenuClick = (e, label) => {
       // console.log( 'clicked: ' + label)
        e.preventDefault()
        e.stopPropagation()
        let menu =  document.getElementById('bio_' + label)
        if( menu != null ){
            if ( !menu.classList.contains('bioSelected')){
               // console.log( 'found button')
                let oldMenus = document.getElementsByClassName('bioSelected')
                if( oldMenus.length > 0 ) {
                  //  console.log('found oldmenu' + oldMenus.length)
                    for( let i = 0; i < oldMenus.length; i++){
                        let menu = oldMenus.item(i)
                       // console.log( menu.classList)
                        menu.classList.remove('bioSelected')
                        let biotext = document.getElementById(menu.getAttribute('data-label').toLowerCase() + '_panel')
                        biotext.style.display = 'none'
                    }
                }else{
                   // console.log('no old menu found')
                }
                menu.classList.add('bioSelected')
                document.getElementById(label + '_panel').style.display = 'block'
            }else{
                console.log('already selected')
            }
        }
    }

    // background: ${props => `url(${CelloPrint}) no-repeat cover`}: 

    const imgStyle = {objectFit: "contain", height:"auto", objectPosition: '60% 40%'};
   
    return (
        <Panel panelId="bio_panel">    
            <PanelHeader title="Bio" />       
            <PanelContent>            
                <BioContainer>  
                    <BioCello>
                        <MenuContainer>
                            {bioMenus.map((item, i) => (
                                <MenuItem id={'bio_' + item.label.toLowerCase()}  data-label={item.label} className={i==0?"bioSelected biomenuitem":"biomenuitem"}  key={i} style={{order:item.order}} onClick={(e)=>HandleMenuClick(e, item.label.toLowerCase())} >
                                {item.label}
                                </MenuItem>
                            ))}                         
                        </MenuContainer>
                    </BioCello>
                    <BioTextContainer>
                            <BioText 
                                id="about_panel"
                                className="bioTextPanel bioTextSelected" 
                                style={{display:"block"}}>
                                <p>Katherine Audas began playing the cello at age 4 on a step stool in her parent's kitchen. Twenty years later, her passion
                                    for the instrument can clearly be heard by audience members. </p><p>As she finishes her schooling and begins her music
                                    career, she dreams of playing in venues around the world. Katherine enjoys performing as a soloist and as an orchestra member.
                                    She also enjoys chamber music and currently plays in a piano trio with her identical twin,  <a href="https://www.jacquelineaudas.com" target="_blank">Jacqueline</a>, a violinist.</p>
                                    <p>Born in Texas but raised in Idaho, Katherine loves spending time
                                    outdoors hiking and camping. She is an avid runner, just recently completing her 5th half-marathon. You can often find her in the kitchen 
                                    experimenting with new recipes that she shares with family and friends.  </p>
                            </BioText>
                            <BioText 
                                id="performances_panel"
                                className="bioTextPanel"
                                style={{display:"none"}} >
                                <b>February 20, 2021</b><br/>
                                <span>Northshore Chamber Music Festival<br/>
                                Northbrook, IL
                                </span>
                                <br/><br/><hr/>
                                <b>March 7, 2020</b><br/>
                                <span>Richardson Symphony Orchestra<br/>
                                Variations on a Rococo Theme for Cello and Orchestra, Op. 33<br/>                               
                                Richardson, TX
                                </span>
                                <br/><br/>
                                <hr/>
                                     <p>Katherine has appeared as a soloist with numerous symphonies, including the Houston Symphony at the 
                                Ima Hogg Competition, the Michoacán Symphony, the Shepherd School of Music Symphony Orchestra, the Boise Philharmonic, 
                                the Walla Walla Symphony, the San Juan Symphony, the McCall SummerFest Orchestra, and the Music in the Mountains Festival Orchestra. </p><p>She has performed in masterclasses 
                                for Gautier Capuçon, Lynn Harrell, Ko Iwasaki, Alisa Weilerstein, Clive Greensmith, Joel Krosnick, and Lawrence Lesser.</p>
                            </BioText>
                            <BioText 
                                id="education_panel"
                                className="bioTextPanel"
                                style={{display:"none"}} >
                                <p>Katherine Audas currently is pursuing her masters degree in cello performance with Brinton Averil Smith
                                at Rice University’s Shepherd School of Music, where she holds the Appassionata Endowed Scholarship in Music. 
                                </p><p>As an undergraduate, she studied with Norman Fischer. She graduated cum laude in 2018 from Rice University 
                                with an Bachelor of Music in cello performance.</p><p>Prior to college, she studied with Dr. Jesús Castro-Balbi 
                                and Dr. William Wharton.</p>
                               
                            </BioText>
                            <BioText 
                                id="honors_panel"
                                className="bioTextPanel"
                                style={{display:"none"}} >
                                <ul>
                                    <li>2020: First prize, Ann and Charles Eisemann International Young Artists Competition</li>
                                    <li>2019: Silver medal at the IX Concurso Internacional Violonchelo Carlos Prieto</li>
                                    <li>2019: Finalist and Audience Favorite, Ima Hogg Competition</li>
                                    <li>2018: Winner, The Shepherd School of Music Concerto Competition</li>
                                    <li>2016: Grand Prize at the Enkor International Music Competition</li>
                                    <li>2015: Gold Medal and the Audience Favorite Award at the Young Texas Artists Music Competition</li>                    
                                </ul>
                            </BioText> 
                            <BioText 
                                id="repertoire_panel"
                                className="bioTextPanel"
                                style={{display:"none"}} >
                                <ul>
                                    <li>Barber: Concerto in A minor</li>
                                    <li>Bloch: Schelomo Rhapsodie Hebraique</li>
                                    <li>Brahms: Concerto for Violin and Cello in A minor</li>
                                    <li>Dvorak: Concerto in B minor</li>
                                    <li>Elgar: Concerto in E minor</li>
                                    <li>Haydn: Concerto in C Major</li>
                                    <li>Haydn: Concerto in D Major</li>
                                    <li>Lalo: Concerto in D minor</li>
                                    <li>Prokofiev: Sinfonia Concertante</li>
                                    <li>Saint-Saens: Concerto in A minor</li>                                                                    
                                    <li>Tchaikovsky: Variations on a Rococo Theme</li>
                                </ul>
                            </BioText> 
                            <BioText 
                                id="instrument_panel" 
                                className="bioTextPanel"
                                style={{display:"none"}} >
                                <p>Katherine performs on a composite 1696 Grancino cello.</p>
                            </BioText>  
                        </BioTextContainer>                          
                    <BioPic>
                        <SiteImage imageStyle={imgStyle} imageName="blackjacket3"/>
                    </BioPic>            
                </BioContainer>               
            </PanelContent>            
      </Panel>
    )
  }

  export default BioPanel;


