import React from 'react'
import Panel from './panel'
import styled from '@emotion/styled'
import SiteImage from '../../images/site_images'


const HomePanel = () => {

    const PanelContent = styled('article')`
      position: relative;
      display: grid;
      align-items: end;    
      width: 100%;
      height:calc(100vw * 10/16);
      border-bottom: solid 10px gray;
    `;

    const imgStyle = {objectFit:"contain"};
   
    return (
        <Panel panelId="home_panel"> 
          <PanelContent>           
            <SiteImage imageStyle={imgStyle} imageName="reddress" />
          </PanelContent> 
        </Panel>
    )
  }

  export default HomePanel;


