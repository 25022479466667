import React, {Component} from 'react'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import SiteImage from './site_images'
import SiteOverlay from '../components/overlay'
import GalleryImgData from './galleryImageData'
import outOfViewport from '../utils/outOfViewport'
import LeftArrowCtl from '../components/ui/leftarrowcontrol'
import RightArrowCtl from '../components/ui/rightarrowcontrol'

const PanelParent = styled('div')`
    display:block;
    position: relative;
    width: 100%;
    height: auto;
`;


const GalleryContainer = styled('div')` 
    position:relative;
    max-width: 100%;
    overflow-x: auto;
    overflow-y:hidden;
    white-space:nowrap;
    display:inline-flex;
    flex-direction: row; 
`;

const GalleryPic = styled('div')`
    flex-shrink: 0;
    min-width: auto;
    white-space: nowrap;
    overflow:hidden;
    margin-right:.3rem;
    height:100%;
    cursor: pointer;
    transition: transform .5s;
    :hover{
        transform: scale(1.05);
    }
`;


const imgContStyle = { 
    position: 'absolute',
    maxWidth: '100%',
    maxHeight: '100%',
    margin: 'auto',
    top: 0,
    left:  0,
    bottom: 0,
    right: 0,
}

const leftArrowProps = {
    position:'absolute',
    left: '1%',
    top: '50%',
    transform: 'translateY(-50%)',
    width: 'calc(3rem + 1vw)',
    opacity: 0.9
}

const rightArrowProps = {
   position: 'absolute',
    right: '1%',
    top: '50%',
    transform: 'translateY(-50%)',
    width: 'calc(3rem + 1vw)',
    opacity: 0.9
}

class Gallery extends Component {
    constructor(props) {
        super(props)

        this.state = {}
        this.state.overLayImage = "reddress_depot"
        this.state.imageData = props.imageData
        this.state.showOverlay = false
        this.state.showLeftArrow = false
        this.state.showRightArrow = false
        this.scrolling = false
        this.state.imageStyle = { objectFit: 'cover', objectPosition: '50% 50%', alt:'picture of Katherine'}
        this.state.style = {width:'calc(10rem + 5vw)', height:'calc(13rem + 11vw'}
        this.state.images = GalleryImgData(this.state.imageData)
    }

    componentDidMount (){
      if( typeof document != 'undefined'){
        this.DetermineArrowVisibility()
       }
    }

    DetermineArrowVisibility = () => {
        let galleryDiv = document.querySelector('#XGalleryDiv')
        let lastGalleryPic = galleryDiv.lastChild
        let out = outOfViewport(lastGalleryPic)
        this.setState({showRightArrow : out.right})
 
        let firstGalleryPic = galleryDiv.firstChild
        out = outOfViewport(firstGalleryPic)
        this.setState({showLeftArrow : out.left})
    }

     
    HandleScroll = (e) => {
        var isScrolling;
        window.clearTimeout(isScrolling)
        let callback = this.DetermineArrowVisibility
		isScrolling = setTimeout(function() {
			callback()
		}, 500)
    }

     HandlePicClick = (e) => {
        e.preventDefault()
        e.stopPropagation()
        let imgFile = e.target.getAttribute('itemProp')
        this.setState({overLayImage:imgFile})
       
        if ( typeof document !== 'undefined'){
            document.addEventListener('keyup', this.OverlayHandleKeyup )
            //document.addEventListener('click', this.CloseOverlay) 
        
                  
        }
        this.setState({showOverlay:true})
        document.dispatchEvent(new Event('OverlayOpen'))
    }

     OverlayHandleKeyup = (e) => {
         
        //right or left arrow scroll through pics
        if((( e.which === 39)||(e.which === 37))&& (this.state.showOverlay === true)){ // right or left arrow scroll
            e.preventDefault()
            e.stopPropagation()
            
            let dir = e.which === 39? 1 : -1
            this.SwapOverlayPic(dir)           
            
        }else{
            // on esc key close viewer if open
             if(( e.which === 27 )&&(this.state.showOverlay === true)){
                e.preventDefault()
                e.stopPropagation()
                 this.CloseOverlay(e)
            }
        }
     }

     SwapOverlayPic = (dir) => {
        let j = 0
        let i = this.state.images.indexOfObjectByProperty('key', this.state.overLayImage)
        if ( i !== -1){
            j = i + dir
            if( j < 0 ){
              j = this.state.images.length -1
            }else{
              if ( j > this.state.images.length -1){
                  j = 0
              }
            } 
            this.setState({overLayImage:this.state.images[j].key})
        }
     }

     CloseOverlay = () => {
         if(typeof document !== 'undefined'){
             document.removeEventListener('click', this.CloseOverlay)
             document.removeEventListener('keyup', this.OverlayHandleKeyup)
         }      
         this.setState({showOverlay:false})
     }

     HandleRightArrowClick = (e) => {
        e.preventDefault()
        e.stopPropagation()
         if( typeof document != 'undefined'){
             let galleryDiv = document.querySelector('#XGalleryDiv')
             if ( galleryDiv !=null){
                let rect = galleryDiv.getBoundingClientRect()
                let options = {
                    left: galleryDiv.scrollLeft + rect.width,
                    top: 0,
                    behavior: 'smooth'
                }
                galleryDiv.scroll(options)
             }
         }
     }

     HandleLeftArrowClick = (e) => {
        e.preventDefault()
        e.stopPropagation()
        if( typeof document != 'undefined'){
            let galleryDiv = document.querySelector('#XGalleryDiv')
            if ( galleryDiv !=null){
               let rect = galleryDiv.getBoundingClientRect()
               let options = {
                   left: galleryDiv.scrollLeft - rect.width,
                   top: 0,
                   behavior: 'smooth'
               }
               galleryDiv.scroll(options)
            }
        }
    }
        
    render=()=>{
        
        return(
            <PanelParent>                         
                <GalleryContainer id="XGalleryDiv" onScroll={this.HandleScroll}>
                {this.state.images.map( (image, i) => (
                    <GalleryPic  
                        id = {'gallerypic_' + i}
                        className = 'gallerypic'
                        key={i}
                        tabIndex={0}
                        onClick={this.HandlePicClick}
                        >
                        <Img itemProp={image.key} imgStyle={image.imgStyle} style={this.state.style} fluid={image.node.childImageSharp.sizes}/>
                    </GalleryPic>
                ))} 
                
                </GalleryContainer>
               
                { this.state.showRightArrow ? <RightArrowCtl tabIndexNum={0} wrapperProps={rightArrowProps} clickCallback={this.HandleRightArrowClick} /> : null }
                { this.state.showLeftArrow ? <LeftArrowCtl tabIndexNum={0}  wrapperProps={leftArrowProps} clickCallback={this.HandleLeftArrowClick} />: null }
           
                <SiteOverlay closeCallback={this.CloseOverlay} arrowClickCallback={this.SwapOverlayPic} id="gallery_overlay">
                    <SiteImage wrapperStyle={imgContStyle} imageStyle={{ objectFit:'contain'}} imageName={this.state.overLayImage} />
                </SiteOverlay>  
  
            </PanelParent>
        )
    }
}

export default Gallery

Gallery.propTypes = {
    imageData: PropTypes.array
  }