

const useVideoData = () => {
    const videos = {}
        videos.Cassado = {
                id: "cassadoMP4",
                title: "Cassado: Suite for Solo Cello, Preludio-fantasia", 
                src:"https://d3tx3k6ax12aot.cloudfront.net/KA/Cassado/SD/E0/Cassado.mp4", 
                poster: "https://d3tx3k6ax12aot.cloudfront.net/KA/Cassado/Cassado_600.png",
                type: "video/mp4"}
        videos.Proko = {
                id: "prokoMP4",
                title: "Prokofiev: Sinfonia Concertante, Andante con moto", 
                src: "https://d3tx3k6ax12aot.cloudfront.net/KA/Prokofiev3/SD/E0/Prokofiev3.mp4", 
                poster: "https://d3tx3k6ax12aot.cloudfront.net/KA/Prokofiev3/Prokofiev_600.png",
                type: "video/mp4"}
        videos.Schelomo = {
                id: "schelomoMP4",
                title: "Bloch: Schelomo, Rhapsodie Hebraique", 
                src: "https://d3tx3k6ax12aot.cloudfront.net/KA/Schelomo/SD/E0/Schelomo.mp4", 
                poster:"https://d3tx3k6ax12aot.cloudfront.net/KA/Schelomo/Schelomo_600.png",
                type:"video/mp4"}
        videos.Trio = {
                id: "beethovenMP4",
                title: "Beethoven: Piano Trio in D Major, op. 70 No. 1 “Ghost”, Allegro vivace e con brio", 
                src: "https://d3tx3k6ax12aot.cloudfront.net/Beethoven/SD/E0/Beethoven.mp4", 
                poster:"https://d3tx3k6ax12aot.cloudfront.net/Beethoven/Beethoven_600.png",
                type: "video/mp4"}
    
    return videos
      
}

export default useVideoData;