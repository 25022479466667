import React from 'react'
import Panel from './panel'
import styled from '@emotion/styled'
import VideoGallery from '../../videos/videoGalleryWithOverlay'
import PanelHeader from './panelHeader'

const MusicPanel = () => {
    
    const PanelContainer = styled('article')`
      width: 100%;
      border-bottom: solid 10px gray; 
    `;
  
    return (
        <Panel panelId="music_panel">            
            <PanelHeader title="Music" />
            <PanelContainer>
              <VideoGallery />
            </PanelContainer>           
      </Panel>
    )
  }

  export default MusicPanel;


